import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";
import "../../index.css";
import Popup from "../../Components/Popup";
import Loader from "../../Components/Loader";
import { DocumentData, GeoPoint, Timestamp } from "firebase/firestore";
import {
  Marker,
  GoogleMap,
  useLoadScript,
  Libraries,
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { Combobox, ComboboxInput } from "@reach/combobox";
import {
  createProject,
  saveProject,
} from "../../Backend/Projects/handleProject";
import moment from "moment";
import { toast } from "sonner";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  statusP?: string;
  project?: DocumentData;
  modify?: boolean;
  setModify?: React.Dispatch<React.SetStateAction<boolean>>;
}

type popup = {
  text: string;
  state: string;
};

type geoPoint = {
  lat: number;
  lng: number;
};

const librairies = ["places"] as Libraries;

function CreateProject({
  open,
  setOpen,
  project,
  statusP,
  modify,
  setModify,
}: Props) {
  const mapRef = useRef();
  const divRef = useRef<HTMLUListElement>(null);

  const [popup, setPopup] = useState<popup>({
    state: "success",
    text: "Invalid error",
  });
  const [openFetch, setOpenFetch] = useState<boolean>(false);
  const [marker, setMarker] = useState<geoPoint | null>(null);
  const [center, setCenter] = useState({ lat: 44, lng: -80 });
  const [name, setName] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [description, setDescription] = useState<string>("");
  const [started, setStarted] = useState<string>("");
  const [end, setEnd] = useState<string>("");
  const [address, setAddress] = useState<string>("");

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        divRef.current &&
        event.target instanceof Node &&
        !divRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [divRef, setOpen]);

  // Geocode.setKey("AIzaSyBIcdE3VSoC8-kVx7EpBozMr_8EjOeqHxo");
  const onLoad = useCallback((map: any) => (mapRef.current = map), []);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyB-sqdG_8f_rhghXPFr2rg-T5D-VTX2_3w",
    libraries: librairies,
  });

  const {
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  useEffect(() => {
    console.log(status, data);
  }, [status, data]);

  const handleSelect = async (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    value: string
  ) => {
    setMarker(null);
    setValue(value, false);
    clearSuggestions();

    const results = await getGeocode({ address: value });
    const { lat, lng } = await getLatLng(results[0]);
    setMarker({
      lat: lat,
      lng: lng,
    });
    setCenter({
      lat: lat,
      lng: lng,
    });

    getDetailsFromLatLng(lat, lng);
  };

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    let res = event.latLng && JSON.stringify(event.latLng.toJSON(), null, 2);
    let latLng = res && JSON.parse(res);
    setMarker({
      lat: latLng.lat,
      lng: latLng.lng,
    });

    setCenter({
      lat: latLng.lat,
      lng: latLng.lng,
    });

    getDetailsFromLatLng(latLng.lat, latLng.lng);
  };

  const getDetailsFromLatLng = async (lat: number, lng: number) => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyB-sqdG_8f_rhghXPFr2rg-T5D-VTX2_3w`
    );
    const data = await response.json();

    if (data.results && data.results.length > 0) {
      setAddress(data.results[0].formatted_address);
      setValue(data.results[0].formatted_address);
    }
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (value !== "") {
      setOpenFetch(true);
    }

    setValue(e.target.value);
  };

  const options = useMemo(
    () => ({
      mapId: "676d78e02d8a6eb1",
      disableDefaultUI: true,
      clickableIcons: false,
    }),
    []
  );

  const dateToString = (timestampObj: {
    seconds: number;
    nanoseconds: number;
  }): string => {
    const timestampInMilliseconds =
      timestampObj.seconds * 1000 + timestampObj.nanoseconds / 1000000;
    const date = new Date(timestampInMilliseconds);
    const formattedDate = moment(date).format("YYYY-MM-DDTHH:mm");
    return formattedDate;
  };

  useEffect(() => {
    if (project !== undefined) {
      setName(project.name);
      setDescription(project.description);
      setValue(project.location.address);
      setLocation(project.location.address);
      setMarker({
        lat: project.location.position.latitude,
        lng: project.location.position.longitude,
      });
      setCenter({
        lat: project.location.position.latitude,
        lng: project.location.position.longitude,
      });
      setStarted(dateToString(project.start));
      setEnd(dateToString(project.end));

      setOpenFetch(false);

      return;
    }
  }, [open, project, setValue]);

  useEffect(() => {
    if (!open) {
      setName("");
      setDescription("");
      setLocation("");
      setMarker(null);
      setStarted("");
      setEnd("");
      setModify !== undefined && setModify(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const fill = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    let target = e.currentTarget;

    if (target.name === "name") {
      setName(target.value);
    } else if (target.name === "description") {
      setDescription(target.value);
    } else if (target.name === "location") {
      setLocation(target.value);
    } else if (target.name === "started") {
      setStarted(target.value);
    } else if (target.name === "end") {
      setEnd(target.value);
    }
  };

  useEffect(() => {
    setAddress("Burval");
    setMarker({
      lat: 12.370839,
      lng: -1.484947,
    });
  }, []);

  const saveTeam = () => {
    console.log(name, location, end);
    if (name === "" || location === "" || end === "") {
      // setPopup({
      //   state: "error",
      //   text: "Remplissez correctement les champs.",
      // });
      // setIsVisible(true);
      toast.error("Remplissez correctement les champs.");
      console.log("is returned");
      return;
    }

    const result = window.confirm(
      `Voulez-vous vraiment ${
        modify ? "modifier les informations du" : "créer le"
      } projet "${name}"?`
    );

    let geoPoint = marker && new GeoPoint(marker.lat, marker.lng);

    if (result) {
      if (modify && project !== undefined) {
        saveProject(
          project,
          name,
          description,
          { position: new GeoPoint(0, 0), address: location },
          setIsLoading,
          setOpen,
          Timestamp.fromDate(new Date(end)),
          Timestamp.fromDate(new Date(started))
        );
      } else if (!modify) {
        const finalStarted =
          started === ""
            ? Timestamp.fromDate(new Date())
            : Timestamp.fromDate(new Date(started));

        createProject(
          name,
          description,
          { position: new GeoPoint(0, 0), address: location },
          setIsLoading,
          setOpen,
          Timestamp.fromDate(new Date(end)),
          statusP,
          finalStarted
        );
      }
    }
  };

  return (
    <div
      className={`w-full min-h-[100vh] ${
        open ? "show-window" : "hide-window"
      } fixed top-0 left-0 z-[9999]`}
    >
      {/* <Popup isVisible={isVisible} infos={popup} /> */}
      <div className="fixed w-full h-[100vh] backdrop-blur-[1.5px] bg-[#e2e2e233]"></div>
      <div className="create-team pt-[0.5em] z-[9999] pb-[70px] overflow-hidden overflow-y-auto fixed bottom-0 md:top-[50%] md:left-[50%] w-full min-w-full md:min-w-0 h-[75%] md:w-[45%] max-w-[400px] md:h-[90%] md:max-h-[600px] md:translate-x-[-50%] md:translate-y-[-50%] flex items-center justify-center bg-white custom-shadow rounded-[12px] ">
        <div className="bottom-buttons fixed flex items-center justify-evenly bg-gray-50 border-t-[1px] border-gray-300 bottom-0 w-full h-[60px] py-[10px]">
          <button
            onClick={(e) => setOpen(false)}
            className="flex items-center justify-center bg-white hover:cursor-pointer w-[47.5%] h-[42.5px] rounded-[8px] border-[1px] border-gray-300 text-[#333] bg-transparent"
          >
            <label className="hover:cursor-pointer">Annuler</label>
          </button>
          <button
            onClick={saveTeam}
            className="flex items-center justify-center hover:cursor-pointer w-[47.5%] h-[42.5px] rounded-[8px] border-[1px] border-blue-500 bg-blue-600 text-white"
          >
            {isLoading ? (
              <Loader />
            ) : (
              <label className="hover:cursor-pointer">
                {modify ? "Modifier le projet" : "Créer un projet"}
              </label>
            )}
          </button>
        </div>
        <div className="for--create-team relative overflow-y-auto z-0 flex items-center justify-start flex-col w-full h-full">
          {/* TOP */}
          <div className="create-team-top flex items-center justify-center w-full h-auto py-[10px]">
            <div className="for--create-team-top flex items-center justify-between w-[90%] h-auto">
              <div className="create-team-txt flex items-start justify-start flex-col">
                <label className="text-black text-[20px] font-[500]">
                  {modify ? "Modifier le" : "Créer un"} projet
                </label>
                <p className="text-[14px] text-start text-gray-400 font-[400] max-w-[85%] mt-[8px]">
                  Un projet est une entreprise temporaire dans le but de créer
                  un produit ou un service.
                </p>
              </div>

              <div className="create-team-icon flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-person-workspace w-[40px] h-[40px]"
                  viewBox="0 0 16 16"
                >
                  <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                  <path d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z" />
                </svg>
              </div>
            </div>
          </div>
          {/* INFOS */}
          <div className="create-team-infos mt-[1.25em] flex items-start justify-start flex-col w-[90%] h-auto">
            <div className="common-for-infos flex items-start justify-start flex-col w-full h-auto mb-[1.15em]">
              <label className="text-[13px] font-[400]">Nom du projet</label>
              <div className="field mt-[5px] w-full h-[37.5px] px-[8px] border-[1.5px] border-[#F4F3F8] rounded-[6px] overflow-hidden">
                <input
                  value={name}
                  onChange={(e) => fill(e)}
                  name="name"
                  type="text"
                  placeholder="Test du logiciel"
                  className="w-[calc(100%-16px)] text-[16px] placeholder:text-[14px] h-full outline-none border-none"
                />
              </div>
            </div>
            <div className="common-for-infos flex items-start justify-start flex-col w-full h-auto mb-[1.15em]">
              <label className="text-[13px] font-[400]">
                Description{" "}
                <span className="text-[10px] text-gray-400">(optionnel)</span>
              </label>
              {!modify && (
                <div className="field mt-[5px] w-full h-[37.5px] px-[8px] border-[1.5px] border-[#F4F3F8] rounded-[6px] overflow-hidden">
                  <input
                    value={description}
                    onChange={(e) => fill(e)}
                    type="text"
                    name="description"
                    placeholder="Verifier si tout marche."
                    className="w-[calc(100%-16px)] text-[16px] placeholder:text-[14px] h-full outline-none border-none"
                  />
                </div>
              )}

              {modify && (
                <div className="field pt-[5px] mt-[5px] w-full h-auto px-[8px] border-[1.5px] border-[#F4F3F8] rounded-[6px] overflow-hidden">
                  <textarea
                    value={description}
                    onChange={(e) => fill(e)}
                    name="description"
                    placeholder="Verifier si tout marche."
                    className="w-full text-[16px] placeholder:text-[14px] h-[55px] min-h-[55px] resize-y outline-none border-none"
                  />
                </div>
              )}
            </div>
            <div className="common-for-infos flex items-start justify-start flex-col w-full h-auto mb-[1.15em]">
              <label className="text-[13px] font-[400]">Localisation</label>
              <div className="field mt-[5px] w-full h-[37.5px] px-[8px] border-[1.5px] rounded-[6px]border-[1.5px] border-[#F4F3F8] rounded-[6px] overflow-hidden">
                <input
                  value={location}
                  onChange={(e) => fill(e)}
                  name="location"
                  type="text"
                  placeholder="Tampouy, Ouagadougou, Burkina Faso"
                  className="w-[calc(100%-16px)] text-[16px] placeholder:text-[14px] h-full outline-none border-none"
                />
              </div>
            </div>
            {/* <div className="common-for-infos flex items-start justify-start flex-col w-full h-auto mb-[1.15em]">
              <label className="text-[13px] font-[400]">Localisation</label>

              <Combobox className="autocomplete relative flex items-start justify-start flex-col w-full h-auto mt-[5px]">
                <div className="search-bar-autocomplete mb-[8px] rounded-[8px] overflow-hidden flex items-center justify-between w-full h-[40px] custom-border">
                  <div className="w-[45px] h-full bg-gray-600 text-white flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-[22px] h-[22px]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                      />
                    </svg>
                  </div>
                  <ComboboxInput
                    value={value}
                    onChange={(e) => handleInput(e)}
                    placeholder="Rechercher un lieu"
                    className="w-[calc(100%-55px)] placeholder:text-[14px] h-full border-none outline-none"
                  />
                </div>
                {data.length > 0 && (
                  <>
                    {openFetch && (
                      <ul
                        ref={divRef}
                        className="fetch-results-autocomplete overflow-hidden overflow-y-auto z-40 rounded-[8px] absolute translate-y-[100%] bottom-0 w-full h-auto max-h-[250px] bg-white custom-shadow"
                      >
                        {status === "OK" &&
                          data.map(
                            ({
                              place_id,
                              description,
                              structured_formatting,
                            }) => (
                              <li
                                key={place_id}
                                onClick={(event) =>
                                  handleSelect(event, description)
                                }
                                className="common-for-location hover:cursor-pointer border-b-[1.33px] border-gray-300 flex items-center justify-center w-full h-auto px-[1em] py-[0.25em]"
                              >
                                <div className="flex items-start justify-start flex-col w-full h-full">
                                  <label className="text-[13px] hover:cursor-pointer font-[500] text-[#333] mb-[2px]">
                                    {structured_formatting.main_text}
                                  </label>
                                  <small className="text-[11px] hover:cursor-pointer font-[400] text-gray-400">
                                    {structured_formatting.secondary_text}
                                  </small>
                                </div>
                              </li>
                            )
                          )}
                      </ul>
                    )}
                  </>
                )}
              </Combobox>

              <div className="rounded-[8px] overflow-hidden mt-[5px] custom-border h-[200px] w-full">
                {isLoaded && (
                  <GoogleMap
                    zoom={12}
                    center={center}
                    options={options}
                    onLoad={onLoad}
                    onClick={(event) => handleMapClick(event)}
                    mapContainerClassName="w-full h-full"
                  >
                    {marker && <Marker position={marker} />}
                  </GoogleMap>
                )}
              </div>
            </div> */}
            {/* <div className="common-for-infos flex items-start justify-start flex-col w-full h-auto mb-[1.15em]">
              <label className="text-[13px] font-[400]">Localisation</label>
              <div className="field mt-[5px] w-full h-[37.5px] px-[8px] border-[1.5px] rounded-[6px]border-[1.5px] border-[#F4F3F8] rounded-[6px] overflow-hidden">
                <input
                  value={location}
                  onChange={(e) => fill(e)}
                  name="location"
                  type="text"
                  placeholder="Tampouy, Ouagadougou, Burkina Faso"
                  className="w-[calc(100%-16px)] text-[16px] placeholder:text-[14px] h-full outline-none border-none"
                />
              </div>
            </div> */}
            <div className="common-for-infos flex items-start justify-start flex-col w-full h-auto mb-[1.15em]">
              <label className="text-[13px] font-[400]">
                Début de la tache{" "}
                <span className="text-[10px] text-gray-400">(optionnel)</span>
              </label>
              <div className="field mt-[5px] w-full h-[37.5px] px-[8px] border-[1.5px] border-[#F4F3F8] rounded-[6px] overflow-hidden">
                <input
                  value={started}
                  onChange={(e) => fill(e)}
                  type="datetime-local"
                  name="started"
                  placeholder="Verifier si tout marche."
                  className="w-[calc(100%-16px)] text-[16px] placeholder:text-[14px] h-full outline-none border-none"
                />
              </div>
            </div>
            <div className="common-for-infos flex items-start justify-start flex-col w-full h-auto mb-[1.15em]">
              <label className="text-[13px] font-[400]">Fin de la tache </label>
              <div className="field mt-[5px] w-full h-[37.5px] px-[8px] border-[1.5px] border-[#F4F3F8] rounded-[6px] overflow-hidden">
                <input
                  value={end}
                  onChange={(e) => fill(e)}
                  type="datetime-local"
                  name="end"
                  placeholder="Verifier si tout marche."
                  className="w-[calc(100%-16px)] text-[16px] placeholder:text-[14px] h-full outline-none border-none"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateProject;
