import React, { useState, useEffect, Fragment } from "react";
import "../../index.css";
import Popup from "../../Components/Popup";
import Loader from "../../Components/Loader";
import { DocumentData, Timestamp } from "firebase/firestore";
import { saveTask, modifyTask } from "../../Backend/Projects/handleProject";
import moment from "moment";
import MakeChoice from "./MakeChoice";
import { toast } from "sonner";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

const predefinedTasks = [
  {
    id: 0,
    title: "N'est pas une tâche prédéfinie",
    description: "",
  },
  {
    id: 1,
    title: "-----Liste des tâches prédéfinies-----",
    description: "",
    isHeader: true,
    disabled: true,
  },
  {
    id: 2,
    title: "Maintenance préventive",
    description:
      "Effectuer une vérification complète des équipements selon le calendrier établi",
  },
  {
    id: 3,
    title: "Inspection de sécurité",
    description:
      "Vérifier tous les dispositifs de sécurité et compiler un rapport détaillé",
  },
  {
    id: 4,
    title: "Formation des nouveaux employés",
    description:
      "Organiser et conduire une session de formation pour les nouveaux membres de l'équipe",
  },
];

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  status: string;
  project: DocumentData;
  modify?: boolean;
  fromLocation?: boolean;
  setFromLocation?: React.Dispatch<React.SetStateAction<boolean>>;
  task?: DocumentData;
  setTask?: React.Dispatch<React.SetStateAction<object | undefined>>;
  setModify?: React.Dispatch<React.SetStateAction<boolean>>;
}

type popup = {
  text: string;
  state: string;
};

function CreateTask({
  open,
  setOpen,
  project,
  status,
  task,
  modify,
  fromLocation,
  setFromLocation,
  setTask,
  setModify,
}: Props) {
  const [popup, setPopup] = useState<popup>({
    state: "success",
    text: "Invalid error",
  });
  // const [finalStatus, setFinalStatus] = useState(status);
  const [name, setName] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [description, setDescription] = useState<string>("");
  const [started, setStarted] = useState<string>("");
  const [end, setEnd] = useState<string>("");
  const [duplicateValue, setDuplicateValue] = useState<string>("");
  const [selectedChoice, setSelectedChoice] = useState<string>("toDo");
  const [selectedTask, setSelectedTask] = useState(predefinedTasks[0]);

  function dateToString(timestampObj: {
    seconds: number;
    nanoseconds: number;
  }): string {
    const timestampInMilliseconds =
      timestampObj.seconds * 1000 + timestampObj.nanoseconds / 1000000;
    const date = new Date(timestampInMilliseconds);
    const formattedDate = moment(date).format("YYYY-MM-DDTHH:mm");

    return formattedDate;
  }

  useEffect(() => {
    if (task) {
      console.log(task);
      setName(task.name);
      setDescription(task.description);
      setLocation(task.location);
      setStarted(dateToString(task.start));
      setEnd(dateToString(task.end));
      setSelectedChoice(task.status);

      task.duplicate && setDuplicateValue(task.duplicate.days);
    }

    // if (task !== undefined && fromLocation === true) {
    //   setName(task.name);
    //   setDescription(task.description);
    //   setLocation(task.location);
    //   setStarted(dateToString(task.start));
    //   setEnd(dateToString(task.end));
    //   setSelectedChoice(task.status);

    //   return;
    // }

    // if (task !== undefined && fromLocation === false) {
    //   setName(task.name);
    //   setDescription(task.description);
    //   setLocation(task.location);
    //   setStarted(dateToString(task.start));
    //   setEnd(dateToString(task.end));
    //   setSelectedChoice(task.status);

    //   // task.duplicate === null
    //   //   ? setDuplicateValue("0")
    //   //   : setDuplicateValue(task.duplicate.days);
    // }

    // if (task !== undefined && fromLocation === undefined) {
    //   setName(task.name);
    //   setDescription(task.description);
    //   setLocation(task.location);
    //   setStarted(dateToString(task.start));
    //   setEnd(dateToString(task.end));
    //   setSelectedChoice(task.status);

    //   // task.duplicate === null
    //   //   ? setDuplicateValue("0")
    //   //   : setDuplicateValue(task.duplicate.days);
    // }
  }, [task, open]);

  useEffect(() => {
    if (!open) {
      setName("");
      setDescription("");
      setLocation("");
      setStarted("");
      setEnd("");
      setDuplicateValue("0");
      // setSelectedChoice("");
      setSelectedTask(predefinedTasks[0]);
      setFromLocation !== undefined && setFromLocation(false);
      setModify !== undefined && setModify(false);

      if (setTask !== undefined) {
        setTask(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const fill = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    let target = e.currentTarget;

    if (target.name === "name") {
      setName(target.value);
    } else if (target.name === "description") {
      setDescription(target.value);
    } else if (target.name === "location") {
      setLocation(target.value);
    } else if (target.name === "started") {
      setStarted(target.value);
    } else if (target.name === "end") {
      setEnd(target.value);
    } else if (target.name === "duplicate") {
      setDuplicateValue(target.value);
    }
  };

  function addDaysToTimestamp(
    timestamp: Timestamp,
    daysToAdd: number
  ): Timestamp {
    const date = timestamp.toDate();
    date.setDate(date.getDate() + daysToAdd);

    return Timestamp.fromDate(date);
  }

  const handleTaskSelection = (task: (typeof predefinedTasks)[0]) => {
    setSelectedTask(task);
    if (!task.isHeader) {
      if (task.id === predefinedTasks[0].id) {
        setName("");
        setDescription("");
        return;
      }
      setName(task.title);
      setDescription(task.description);
    }
  };

  const saveTeam = () => {
    if (name === "" || location === "") {
      // setPopup({
      //   state: "error",
      //   text: "Remplissez correctement les champs.",
      // });
      // setIsVisible(true);
      toast.error("Remplissez correctement les champs.");
      return;
    }

    if (selectedChoice === undefined || selectedChoice === "") {
      toast.error("Vous n'avez pas choisi le status de la tâche.");
      return;
    }

    let finalStatus = "";

    if (fromLocation !== undefined) {
      if (selectedChoice !== "") {
        if (selectedChoice === "A faire") {
          finalStatus = "toDo";
        } else if (selectedChoice === "En cours") {
          finalStatus = "doing";
        } else if (selectedChoice === "Terminée") {
          finalStatus = "finished";
        }
      }
    }

    const result = window.confirm(
      `Voulez-vous vraiment ${modify ? "modifier" : "créer"} la tâche ${name}?`
    );

    if (modify && result && task !== undefined) {
      setIsLoading(true);

      finalStatus = task.status;

      if (task.duplicate) {
        // const parsedDays = parseInt(duplicateValue);

        let duplicate = null;

        // if (parsedDays === 0) {
        //   duplicate = null;
        // } else {
        //   duplicate = {
        //     start: task.start,
        //     days: parsedDays,
        //     end: addDaysToTimestamp(task.start, parsedDays),
        //   };
        // }

        console.log(started, end);
        console.log(duplicate);
        modifyTask(
          task.id,
          project,
          name,
          description,
          location,
          finalStatus,
          setPopup,
          setIsVisible,
          setIsLoading,
          setOpen,
          Timestamp.fromDate(new Date(end)),
          Timestamp.fromDate(new Date(started)),
          duplicate,
          modify
        );
      } else {
        modifyTask(
          task.id,
          project,
          name,
          description,
          location,
          finalStatus,
          setPopup,
          setIsVisible,
          setIsLoading,
          setOpen,
          Timestamp.fromDate(new Date(end)),
          Timestamp.fromDate(new Date(started))
        );
      }

      return;
    }

    if (result) {
      if (status !== "") {
        finalStatus = status;
      }

      saveTask(
        project,
        name,
        description,
        location,
        finalStatus,
        setPopup,
        setIsVisible,
        setIsLoading,
        setOpen,
        Timestamp.fromDate(new Date(end)),
        Timestamp.fromDate(new Date(started))
      );
    }
  };

  return (
    <div
      className={`w-full min-h-[100vh] ${
        open ? "show-window" : "hide-window"
      } fixed top-0 left-0 z-[1002]`}
    >
      <Popup isVisible={isVisible} infos={popup} />
      <div className="fixed w-full h-[100vh] backdrop-blur-[1.5px] bg-[#e2e2e233]"></div>
      <div className="create-team pt-[0.5em] z-[1001] pb-[70px] overflow-hidden overflow-y-auto fixed bottom-0 md:top-[50%] md:left-[50%] w-full min-w-full md:min-w-0 h-[75%] md:w-[45%] max-w-[400px] md:h-[90%] md:max-h-[600px] md:translate-x-[-50%] md:translate-y-[-50%] flex items-center justify-center bg-white custom-shadow rounded-[12px] ">
        <div className="bottom-buttons fixed flex items-center justify-evenly bg-gray-50 border-t-[1px] border-gray-300 bottom-0 w-full h-[60px] py-[10px]">
          <button
            onClick={(e) => setOpen(false)}
            className="flex items-center justify-center bg-white hover:cursor-pointer w-[47.5%] h-[42.5px] rounded-[8px] border-[1px] border-gray-300 text-[#333] bg-transparent"
          >
            <label className="hover:cursor-pointer">Annuler</label>
          </button>
          <button
            onClick={saveTeam}
            className="flex items-center justify-center hover:cursor-pointer w-[47.5%] h-[42.5px] rounded-[8px] border-[1px] border-blue-500 bg-blue-600 text-white"
          >
            {isLoading ? (
              <Loader />
            ) : (
              <label className="hover:cursor-pointer">
                {modify ? "Modifier la tache" : "Créer la tache"}
              </label>
            )}
          </button>
        </div>
        <div className="for--create-team relative overflow-y-auto z-0 flex items-center justify-start flex-col w-full h-full">
          {/* TOP */}
          <div className="create-team-top flex items-center justify-center w-full h-auto py-[10px]">
            <div className="for--create-team-top flex items-center justify-between w-[90%] h-auto">
              <div className="create-team-txt flex items-start justify-start flex-col">
                <label className="text-black text-[20px] font-[500]">
                  {modify ? "Modifer la" : "Créer une"} tache
                </label>
                <p className="text-[14px] text-gray-400 font-[400] mt-[8px]">
                  Une tache permet d'assigner un travail à un ou plusieurs
                  membres.
                </p>
              </div>

              <div className="create-team-icon flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-[40px] h-[40px]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122"
                  />
                </svg>
              </div>
            </div>
          </div>
          {/* INFOS */}
          <div className="create-team-infos mt-[1.25em] flex items-start justify-start flex-col w-[90%] h-auto">
            <div className="common-for-infos flex items-start justify-start flex-col w-full h-auto mb-[1.15em]">
              <label className="text-[13px] font-[400] mb-2">
                Tâche prédéfinie
              </label>
              <Listbox
                disabled={isLoading}
                value={selectedTask}
                onChange={handleTaskSelection}
              >
                <div className="relative w-full">
                  <Listbox.Button className="relative w-full h-[37.5px] text-left bg-white px-[8px] border-[1.5px] border-[#F4F3F8] rounded-[6px] cursor-pointer focus:outline-none">
                    <small className="block truncate">
                      {selectedTask.title}
                    </small>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {predefinedTasks.map((task) => (
                        <Listbox.Option
                          key={task.id}
                          value={task}
                          disabled={task.disabled}
                          className={({ active, disabled }) =>
                            `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                              active && !disabled
                                ? "bg-blue-100 text-blue-900"
                                : "text-gray-900"
                            } ${disabled ? "bg-gray-100 cursor-default" : ""} ${
                              task.isHeader ? "font-semibold text-gray-400" : ""
                            }`
                          }
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? "font-medium" : "font-normal"
                                }`}
                              >
                                {task.title}
                              </span>
                              {selected && !task.disabled ? (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
              <div className="flex items-center justify-start mt-2 text-blue-500 gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-badge-info w-[27px] h-[27px]"
                >
                  <path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z" />
                  <line x1="12" x2="12" y1="16" y2="12" />
                  <line x1="12" x2="12.01" y1="8" y2="8" />
                </svg>
                <small>
                  Sélectionner un element dans la liste pour automatiquement
                  remplir le nom et la description de la tâche.
                </small>
              </div>
            </div>
            {/*  */}
            <div className="common-for-infos flex items-start justify-start flex-col w-full h-auto mb-[1.15em]">
              <label className="text-[13px] font-[400]">Nom de la tache</label>
              <div className="field mt-[5px] w-full h-[37.5px] px-[8px] border-[1.5px] border-[#F4F3F8] rounded-[6px] overflow-hidden">
                <input
                  value={name}
                  onChange={(e) => fill(e)}
                  name="name"
                  type="text"
                  disabled={selectedTask.id !== predefinedTasks[0].id}
                  placeholder="Test du logiciel"
                  className="w-[calc(100%-16px)] text-[16px] placeholder:text-[14px] h-full outline-none border-none"
                />
              </div>
            </div>
            <div className="common-for-infos flex items-start justify-start flex-col w-full h-auto mb-[1.15em]">
              <label className="text-[13px] font-[400]">
                Description{" "}
                <span className="text-[10px] text-gray-400">(optionnel)</span>
              </label>
              {!modify && (
                <div className="field mt-[5px] w-full h-[37.5px] px-[8px] border-[1.5px] border-[#F4F3F8] rounded-[6px] overflow-hidden">
                  <input
                    value={description}
                    onChange={(e) => fill(e)}
                    type="text"
                    name="description"
                    disabled={selectedTask.id !== predefinedTasks[0].id}
                    placeholder="Verifier si tout marche."
                    className="w-[calc(100%-16px)] text-[16px] placeholder:text-[14px] h-full outline-none border-none"
                  />
                </div>
              )}

              {modify && (
                <div className="field pt-[5px] mt-[5px] w-full h-auto px-[8px] border-[1.5px] border-[#F4F3F8] rounded-[6px] overflow-hidden">
                  <textarea
                    value={description}
                    onChange={(e) => fill(e)}
                    name="description"
                    placeholder="Verifier si tout marche."
                    className="w-full text-[16px] placeholder:text-[14px] h-[55px] min-h-[55px] resize-y outline-none border-none"
                  />
                </div>
              )}
            </div>
            <div className="common-for-infos flex items-start justify-start flex-col w-full h-auto mb-[1.15em]">
              <label className="text-[13px] font-[400]">Localisation</label>
              <div className="field mt-[5px] w-full h-[37.5px] px-[8px] border-[1.5px] rounded-[6px]border-[1.5px] border-[#F4F3F8] rounded-[6px] overflow-hidden">
                <input
                  value={location}
                  onChange={(e) => fill(e)}
                  name="location"
                  type="text"
                  placeholder="Tampouy, Ouagadougou, Burkina Faso"
                  className="w-[calc(100%-16px)] text-[16px] placeholder:text-[14px] h-full outline-none border-none"
                />
              </div>
            </div>
            <div className="common-for-infos flex items-start justify-start flex-col w-full h-auto mb-[1.15em]">
              <label className="text-[13px] font-[400]">
                Début de la tache{" "}
                <span className="text-[10px] text-gray-400">(optionnel)</span>
              </label>
              <div className="field mt-[5px] w-full h-[37.5px] px-[8px] border-[1.5px] border-[#F4F3F8] rounded-[6px] overflow-hidden">
                <input
                  disabled={fromLocation || (task && task.duplicate)}
                  value={started}
                  onChange={(e) => fill(e)}
                  type="datetime-local"
                  name="started"
                  placeholder="Verifier si tout marche."
                  className="w-[calc(100%-16px)] text-[16px] placeholder:text-[14px] h-full outline-none border-none"
                />
              </div>
              {modify && task && task.duplicate && (
                <small className="text-red-500 text-[12px] mt-1">
                  La date des tâches dupliquées ne peut pas etre modifiée.
                </small>
              )}
            </div>
            <div className="common-for-infos flex items-start justify-start flex-col w-full h-auto mb-[1.15em]">
              <label className="text-[13px] font-[400]">
                Fin de la tache{" "}
                <span className="text-[10px] text-gray-400">(optionnel)</span>
              </label>
              <div className="field mt-[5px] w-full h-[37.5px] px-[8px] border-[1.5px] border-[#F4F3F8] rounded-[6px] overflow-hidden">
                <input
                  disabled={fromLocation || (task && task.duplicate)}
                  value={end}
                  onChange={(e) => fill(e)}
                  type="datetime-local"
                  name="end"
                  placeholder="Verifier si tout marche."
                  className="w-[calc(100%-16px)] text-[16px] placeholder:text-[14px] h-full outline-none border-none"
                />
              </div>
              {modify && task && task.duplicate && (
                <small className="text-red-500 text-[12px] mt-1">
                  La date des tâches dupliquées ne peut pas etre modifiée.
                </small>
              )}
            </div>
            {task !== undefined &&
              task.duplicate !== null &&
              modify !== true && (
                <div className="common-for-infos flex items-start justify-start flex-col w-full h-auto mb-[1.15em]">
                  <label className="text-[13px] font-[400]">
                    Temps de dupliquement{" "}
                    <span className="text-[10px] text-gray-400">
                      (optionnel)
                    </span>
                  </label>
                  <div className="field mt-[5px] w-full h-[37.5px] px-[8px] border-[1.5px] border-[#F4F3F8] rounded-[6px] overflow-hidden">
                    <input
                      value={duplicateValue}
                      onChange={(e) => fill(e)}
                      type="text"
                      name="duplicate"
                      placeholder="5"
                      className="w-[calc(100%-16px)] text-[16px] placeholder:text-[14px] h-full outline-none border-none"
                    />
                  </div>
                </div>
              )}
            {fromLocation && (
              <div className="common-for-infos flex items-start justify-start flex-col w-full h-auto mb-[1.15em]">
                <MakeChoice
                  open={open}
                  selected={selectedChoice}
                  setSelected={setSelectedChoice}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateTask;
