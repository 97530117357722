import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// const firebaseConfig = {
//   apiKey: "AIzaSyA-UIb-UzgTczNe_S3D45dr0ZZzky50P6w",
//   authDomain: "brocand-test.firebaseapp.com",
//   projectId: "brocand-test",
//   storageBucket: "brocand-test.appspot.com",
//   messagingSenderId: "566174111748",
//   appId: "1:566174111748:web:6915e024c7d99aa8603d51",
//   measurementId: "G-5MBJRDL9XS",
// };

const firebaseConfig = {
  apiKey: "AIzaSyCH_3XXIevnw-3F1dP423CY5OCeBvKACKQ",
  authDomain: "teamask-e9bcd.firebaseapp.com",
  projectId: "teamask-e9bcd",
  storageBucket: "teamask-e9bcd.appspot.com",
  messagingSenderId: "512185217374",
  appId: "1:512185217374:web:3b57db5922b7e3f5cedfcf",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const database = getFirestore(app);
